<template>
    <div id="app_template_add_id">
        <!-- 多媒体消息新增模板  -->
        <el-dialog class="cur_dialog" title="" center :visible.sync="dialog_visible" fullscreen destroy-on-close :show-close="false" :modal-append-to-body="false">
            <!-- 头 -->
            <el-row class="dialog_top">
                <span>多媒体消息新建模板</span>
                <i class="el-icon-close dialog_top_close_button" @click="back()" style="font-size:24px"></i>
            </el-row>

            <!-- 体 -->
            <el-row style="margin:10px 0px 0px;padding:0px 15px;background-color: white;">

                <el-row style="overflow: auto;padding-right:10px;" :style="{height: (content_heigth+'px')}">
                    <el-form :model="form_data" label-width="120px" style="margin-bottom: 12px;">
                        <el-form-item label="标题">
                            <el-input v-model="form_data.title" placeholder="请输入模板标题" size="mini"></el-input>
                        </el-form-item>

                        <el-form-item label="实际发送企业">
                            <el-select v-model="form_data.user_company_id" @change="company_change" placeholder="请从公司列表中选择实际发送的企业" size="mini" style="width:100%"> 
                                <el-option
                                    v-for="item in signCompanyList"
                                    :key="item.value"
                                    :label="item.name"
                                    :value="item.value"
                                    >
                                </el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item label="签名">
                            <el-select v-model="form_data.signword" placeholder="请选择模板使用的签名" size="mini" style="width:100%"> 
                                <el-option
                                    v-for="item in signwordList"
                                    :key="item.id"
                                    :label="item.signword"
                                    :value="item.id"
                                    >
                                </el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item label="模板">
                            <i class="el-icon-plus cursor" @click="add_frame" style="font-size:18px;"></i>
                        </el-form-item>
                        <el-form-item label="模板内容">
                            <template v-for="item1 in template_data">
                                <div style="overflow: auto;float:left; border:1px solid #dddddd; width: 320px; height:400px; text-align: center; padding-top: 10px; margin: 5px;">
                                    <template v-for="(item2,index) in item1">
                                        <!-- text文本内容，图片1， 视频2，音频3 -->
                                        <template v-if="item2.type =='text'">
                                            <h3>{{item2.src}}</h3>
                                        </template>
                                        <template v-else-if="item2.type == 1">
                                            <img :src="item2.src" alt="" style="width:150px; height:250px;">
                                        </template>
                                        <template v-else-if="item2.type == 2">
                                            <video :src="item2.src" controls class="video" width="98%"></video>
                                        </template>
                                        <template v-else-if="item2.type == 3">
                                            <audio :src="item2.src" controls="controls" ref="audio">您的浏览器不支持audio标签</audio>
                                        </template>
                                        <template v-if="index+1 == item1.length">
                                            <el-row style="text-align:center">
                                                <el-button round @click="del_frames(item2.id)" class="cursor">删除</el-button>
                                            </el-row>
                                        </template>
                                    </template>
                                </div>
                            </template>
                        </el-form-item>

                        <el-form-item style="text-align:center">
                            <el-button type="primary" @click="onSubmit()" size="mini">保存</el-button>
                        </el-form-item>
                    </el-form>

                </el-row>

            </el-row>

        </el-dialog>

        <!-- 新增多媒体模板帧 -->
        <MediaTemplateAddFrame :prop_frame_template_i="prop_frame_template_i" 
        :prop_frame_channel_id="prop_frame_channel_id"
        :prop_frame_template_id="prop_frame_template_id" 
        :prop_group_id_1="prop_group_id_1" @add_frame="get_add_frame"></MediaTemplateAddFrame>

    </div>
</template>
<script>
import API from '../../api/api';
import MediaTemplateAddFrame from './media_template_add_frame.vue';//组件 多媒体消息新建模板帧

export default {
    components:{
        MediaTemplateAddFrame
    },
    props:{
        prop_change_i: 0,
        prop_channel_id: '',//通道id
    },
    watch:{//监听props
        prop_change_i(val){
            //数据清除
            this.dataClear();
            if(val == 0){
                this.dialog_visible = false;//弹出框是否可见 
            }else{
                this.dialog_visible = true;//弹出框是否可见
                setTimeout(() => {
                    //请求接口-获取签约公司列表
                    this.getSignCompanyList();
                }, 1);
            }
        }
    },

    data(){
        return{
            dialog_visible: false,//弹出框是否可见  false:不可见

            content_width:300,//内容的=宽度
            content_heigth:500,//内容的高度

            signCompanyList:[],//签约公司列表
            signwordList:[],//签名列表

            form_data:{
                title:'',//标题
                user_company_id:'',//实际发送企业
                signword:'',//签名内容
            },

            //新建多媒体模板帧
            prop_frame_template_i: 0,//新建多媒体模板帧  组件 弹出框是否可见
            prop_frame_channel_id: "",//通道id
            prop_frame_template_id: "",//新建多媒体模板帧  组件  模版id
            prop_group_id_1: -1,//新建多媒体模板第几帧

            template_id:'',//模板ID
            template_data:[],//多媒体模板帧列表
        }
    },
    //第二个生命周期函数，在created 中，data 和 methods 都已经被初始化好了！
    created() {
        //初始化设置
        this.initSetUp();
    },
    //方法
    methods: {
        //初始化设置
        initSetUp(){
            let clientWidth = document.body.clientWidth;
            let clientHeight = document.body.clientHeight;
            // console.log("document.body.clientWidth:"+document.body.clientWidth+" "+document.body.clientHeight);
            this.content_width = clientWidth;
            this.content_heigth = (clientHeight - 80);
        },
         //数据清除
        dataClear(){
            this.form_data = this.$options.data().form_data;//单个恢复初始化
            this.template_data = this.$options.data().template_data;
        },

         //增加模板帧
        add_frame(){
            this.prop_frame_channel_id = this.prop_channel_id;
            this.prop_frame_template_id = this.template_id;
            this.prop_frame_template_i ++;//弹出框是否可见
            this.prop_group_id_1++;//新建多媒体模板第几帧
        },
        //添加模板帧后回调
        get_add_frame(template_id, group_id_1){
            if(template_id != null && template_id != ''){
                this.template_id = template_id;
                this.get_frame_data();
            }else{
                this.prop_group_id_1 = group_id_1-1;
            }
        },
        //多媒体模板帧列表
        get_frame_data(){
            API.MediaTemplateServlet({
                param: "templateFrameList",
                template_id: this.template_id,
            }).then((res) => {
                //console.log(res);
                if (res.recode === 0) {
                    this.template_data = res.list;
                }
            });
        },
        //多媒体模板删除帧
        del_frames(frame_id){
            API.MediaTemplateServlet({
                param: "delTemplateFrame",
                id: frame_id,
            }).then((res) => {
                //console.log(res);
                if (res.recode === 0) {
                    this.get_frame_data();
                }
            });
        },
        //请求接口-获取签约公司列表
        getSignCompanyList(){
            API.CompanyServlet({
                param: "selList",
            }).then((res) => {
                //console.log(res);
                if (res.recode === 0) {
                    this.signCompanyList = res.list;//id + company_name
                    // var obj = {"id":"","company_name":"请选择"}
                    // this.signCompanyList.unshift(obj);//数组第一个位置插入元素
                }
            });
        },
        //实际发送企业切换
        company_change(value,label){
            console.log('操作人选中项发生变化', value+"\t"+label);
            this.getMedaiSign(value);
        },
        //请求接口-报备模板获取签名列表
        getMedaiSign(user_company_id){
            API.MediaSignServlet({
                param: "mmsSignList",
                channel_id: this.prop_channel_id,
                user_company_id: user_company_id,
            }).then((res) => {
                //console.log(res);
                if (res.recode === 0) {
                    this.signwordList = res.list;//id + signword
                }
            });
        },

        //提交
        onSubmit(){
            if(API.isEmtry(this.form_data.title)){
                this.$message.error("请输入模板标题");
                return;
            }
            if(API.isEmtry(this.form_data.user_company_id)){
                this.$message.error("请从公司列表中选择实际发送的企业");
                return;
            }
            if(API.isEmtry(this.form_data.signword)){
                this.$message.error("请选择模板使用的签名");
                return;
            }
            API.MediaTemplateServlet({
                param: "addMediaTemplateXZ",
                channel_id: this.prop_channel_id,
                title: this.form_data.title,
                user_company_id: this.form_data.user_company_id,
                sign_id: this.form_data.signword,
                template_id: this.template_id,
                frames: JSON.stringify(this.template_data),
            }).then((res) => {
                //console.log(res);
                if (res.recode === 0) {
                    this.$message({
                        message: "恭喜您，提交成功",
                        type: "success",
                        offset: 280
                    });
                    this.template_id = "";
                    this.prop_group_id_1 = -1;
                    this.dialog_visible = false;//弹出框是否可见   false:不可见
                    this.$emit("add_template", "");
                }
            });
        },
        //关闭
        back(){
            this.template_id = '';
            this.prop_group_id_1 = -1;
            this.dialog_visible = false;//弹出框是否可见   false:不可见
        },

    }
};

</script>

<style scoped>
/* 增加或编辑弹框中内容距离标题的距离 */
.cur_dialog >>>.el-dialog__body {
    padding: 0px 0px;
}
.cur_dialog >>>.el-dialog__header {
    padding: 0px 0px;
}
/*form表单 间隔*/
.el-form-item {
  margin-bottom: 10px;
}
</style>